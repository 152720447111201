import { LocationPoolType } from "../Types/LocationPoolType";

export let SmallKeyLocationPool: LocationPoolType = [
    "Forest Temple First Chest",
    "Forest Temple Chest Behind Lobby",
    "Forest Temple Well Chest",
    "Forest Temple Floormaster Chest",
    "Forest Temple Red Poe Chest",
    "Bottom of the Well Front Left Hidden Wall",
    "Bottom of the Well Right Bottom Hidden Wall",
    "Bottom of the Well Freestanding Key",
    "Fire Temple Chest Near Boss",
    "Fire Temple Big Lava Room Open Chest",
    "Fire Temple Big Lava Room Bombable Chest",
    "Fire Temple Boulder Maze Lower Chest",
    "Fire Temple Boulder Maze Upper Chest",
    "Fire Temple Boulder Maze Side Room",
    "Fire Temple Boulder Maze Bombable Pit",
    "Fire Temple Highest Goron Chest",
    "Water Temple Torches Chest",
    "Water Temple Central Bow Target Chest",
    "Water Temple Cracked Wall Chest",
    "Water Temple Dragon Chest",
    "Water Temple Central Pillar Chest",
    "Water Temple River Chest",
    "Shadow Temple Early Silver Rupee Chest",
    "Shadow Temple Falling Spikes Upper Chest",
    "Shadow Temple Freestanding Key",
    "Shadow Temple After Wind Hidden Chest",
    "Shadow Temple Hidden Floormaster Chest",
    "Gerudo Training Grounds Stalfos Chest",
    "Gerudo Training Grounds Beamos Chest",
    "Gerudo Training Grounds Hidden Ceiling Chest",
    "Gerudo Training Grounds Freestanding Key",
    "Gerudo Training Grounds Underwater Silver Rupee Chest",
    "Gerudo Training Grounds Hammer Room Switch Chest",
    "Gerudo Training Grounds Eye Statue Chest",
    "Gerudo Training Grounds Near Scarecrow Chest",
    "Gerudo Training Grounds Heavy Block Third Chest",
    "Spirit Temple Child Right Chest",
    "Spirit Temple Early Adult Right Chest",
    "Spirit Temple Sun Block Room Chest",
    "Spirit Temple Statue Hand Chest",
    "Spirit Temple Near Four Armos Chest",
    "Ganons Castle Light Trial Invisible Enemies Chest",
    "Ganons Castle Light Trial Lullaby Chest",
];
