import { LocationPoolType } from "../Types/LocationPoolType";

export let ShopLocationPool: LocationPoolType = [
    "Kokiri Shop Item 1",
    "Kokiri Shop Item 2",
    "Kokiri Shop Item 3",
    "Kokiri Shop Item 4",
    "Kokiri Shop Item 5",
    "Kokiri Shop Item 6",
    "Kokiri Shop Item 7",
    "Kokiri Shop Item 8",
    "Castle Town Bazaar Item 1",
    "Castle Town Bazaar Item 2",
    "Castle Town Bazaar Item 3",
    "Castle Town Bazaar Item 4",
    "Castle Town Bazaar Item 5",
    "Castle Town Bazaar Item 6",
    "Castle Town Bazaar Item 7",
    "Castle Town Bazaar Item 8",
    "Castle Town Potion Shop Item 1",
    "Castle Town Potion Shop Item 2",
    "Castle Town Potion Shop Item 3",
    "Castle Town Potion Shop Item 4",
    "Castle Town Potion Shop Item 5",
    "Castle Town Potion Shop Item 6",
    "Castle Town Potion Shop Item 7",
    "Castle Town Potion Shop Item 8",
    "Bombchu Shop Item 1",
    "Bombchu Shop Item 2",
    "Bombchu Shop Item 3",
    "Bombchu Shop Item 4",
    "Bombchu Shop Item 5",
    "Bombchu Shop Item 6",
    "Bombchu Shop Item 7",
    "Bombchu Shop Item 8",
    "Kakariko Bazaar Item 1",
    "Kakariko Bazaar Item 2",
    "Kakariko Bazaar Item 3",
    "Kakariko Bazaar Item 4",
    "Kakariko Bazaar Item 5",
    "Kakariko Bazaar Item 6",
    "Kakariko Bazaar Item 7",
    "Kakariko Bazaar Item 8",
    "Kakariko Potion Shop Item 1",
    "Kakariko Potion Shop Item 2",
    "Kakariko Potion Shop Item 3",
    "Kakariko Potion Shop Item 4",
    "Kakariko Potion Shop Item 5",
    "Kakariko Potion Shop Item 6",
    "Kakariko Potion Shop Item 7",
    "Kakariko Potion Shop Item 8",
    "Goron Shop Item 1",
    "Goron Shop Item 2",
    "Goron Shop Item 3",
    "Goron Shop Item 4",
    "Goron Shop Item 5",
    "Goron Shop Item 6",
    "Goron Shop Item 7",
    "Goron Shop Item 8",
    "Zora Shop Item 1",
    "Zora Shop Item 2",
    "Zora Shop Item 3",
    "Zora Shop Item 4",
    "Zora Shop Item 5",
    "Zora Shop Item 6",
    "Zora Shop Item 7",
    "Zora Shop Item 8",
];
