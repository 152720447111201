
// for selecting ow, split by " -> ",
// { "region": [0], "from": [1] }
export let OverworldEntrances: string[] = [
    "Castle Grounds -> Castle Town",
    "Castle Town -> Castle Grounds",
    "Castle Town -> Castle Town Entrance",
    "Castle Town -> Temple of Time Exterior",
    "Castle Town Entrance -> Castle Town",
    "Castle Town Entrance -> Hyrule Field",
    "Darunias Chamber -> Death Mountain Crater Lower Local",
    "Death Mountain -> Goron City",
    "Death Mountain -> Kakariko Village Behind Gate",
    "Death Mountain Crater Lower Nearby -> Darunias Chamber",
    "Death Mountain Crater Upper Nearby -> Death Mountain Summit",
    "Death Mountain Summit -> Death Mountain Crater Upper Local",
    "Desert Colossus -> Haunted Wasteland Near Colossus",
    "Gerudo Fortress -> Gerudo Valley Far Side",
    "Gerudo Fortress Outside Gate -> Haunted Wasteland Near Fortress",
    "Gerudo Valley -> Hyrule Field",
    "Gerudo Valley Far Side -> Gerudo Fortress",
    "Goron City -> Death Mountain",
    "Goron City Woods Warp -> Lost Woods",
    "Graveyard -> Kakariko Village",
    "Haunted Wasteland Near Colossus -> Desert Colossus",
    "Haunted Wasteland Near Fortress -> Gerudo Fortress Outside Gate",
    "Hyrule Field -> Castle Town Entrance",
    "Hyrule Field -> Gerudo Valley",
    "Hyrule Field -> Kakariko Village",
    "Hyrule Field -> Lake Hylia",
    "Hyrule Field -> Lon Lon Ranch",
    "Hyrule Field -> Lost Woods Bridge",
    "Hyrule Field -> Zora River Front",
    "Kakariko Village -> Graveyard",
    "Kakariko Village -> Hyrule Field",
    "Kakariko Village Behind Gate -> Death Mountain",
    "Kokiri Forest -> Lost Woods",
    "Kokiri Forest -> Lost Woods Bridge From Forest",
    "Lake Hylia -> Hyrule Field",
    "Lake Hylia -> Zoras Domain",
    "Lon Lon Ranch -> Hyrule Field",
    "Lost Woods -> Goron City Woods Warp",
    "Lost Woods -> Zora River",
    "Lost Woods Beyond Mido -> Sacred Forest Meadow Entryway",
    "Lost Woods Bridge -> Hyrule Field",
    "Lost Woods Bridge -> Kokiri Forest",
    "Lost Woods Forest Exit -> Kokiri Forest",
    "Sacred Forest Meadow Entryway -> Lost Woods Beyond Mido",
    "Temple of Time Exterior -> Castle Town",
    "Zora River -> Lost Woods",
    "Zora River Behind Waterfall -> Zoras Domain",
    "Zora River Front -> Hyrule Field",
    "Zoras Domain -> Lake Hylia",
    "Zoras Domain -> Zora River Behind Waterfall",
    "Zoras Domain Behind King Zora -> Zoras Fountain",
    "Zoras Fountain -> Zoras Domain Behind King Zora",
    "Death Mountain Summit Owl Flight -> Kakariko Impa Ledge",
    "Lake Hylia Owl Flight -> Hyrule Field",
];
