import { LocationPoolType } from "../Types/LocationPoolType";

export let DungeonLocationPool: LocationPoolType = [
    "Deku Tree Compass Room Side Chest",
    "Deku Tree Basement Chest",
    "Deku Tree Slingshot Chest",
    "Deku Tree Slingshot Room Side Chest",
    "Queen Gohma Heart",
    "Dodongos Cavern Bomb Flower Platform",
    "Dodongos Cavern Bomb Bag Chest",
    "Dodongos Cavern End of Bridge Chest",
    "Chest Above King Dodongo",
    "King Dodongo Heart",
    "Boomerang Chest",
    "Barinade Heart",
    "Forest Temple Outside Hookshot Chest",
    "Forest Temple Falling Room Chest",
    "Forest Temple Block Push Chest",
    "Forest Temple Bow Chest",
    "Forest Temple Near Boss Chest",
    "Phantom Ganon Heart",
    "Bottom of the Well Front Center Bombable",
    "Bottom of the Well Center Small Chest",
    "Bottom of the Well Back Left Bombable",
    "Bottom of the Well Defeat Boss",
    "Bottom of the Well Invisible Chest",
    "Bottom of the Well Underwater Front Chest",
    "Bottom of the Well Underwater Left Chest",
    "Bottom of the Well Locked Pits",
    "Bottom of the Well Behind Right Grate",
    "Fire Temple Fire Dancer Chest",
    "Volvagia Heart",
    "Fire Temple Scarecrow Chest",
    "Fire Temple Megaton Hammer Chest",
    "Ice Cavern Iron Boots Chest",
    "Ice Cavern Freestanding PoH",
    "Morpha Heart",
    "Water Temple Dark Link Chest",
    "Shadow Temple Hover Boots Chest",
    "Shadow Temple Invisible Blades Visible Chest",
    "Shadow Temple Invisible Blades Invisible Chest",
    "Shadow Temple Falling Spikes Lower Chest",
    "Shadow Temple Falling Spikes Switch Chest",
    "Shadow Temple Invisible Spikes Chest",
    "Shadow Temple Wind Hint Chest",
    "Shadow Temple After Wind Enemy Chest",
    "Shadow Temple Spike Walls Left Chest",
    "Bongo Bongo Heart",
    "Gerudo Training Grounds Lobby Left Chest",
    "Gerudo Training Grounds Lobby Right Chest",
    "Gerudo Training Grounds Maze Path First Chest",
    "Gerudo Training Grounds Maze Path Second Chest",
    "Gerudo Training Grounds Maze Path Third Chest",
    "Gerudo Training Grounds Maze Path Final Chest",
    "Gerudo Training Grounds Maze Right Central Chest",
    "Gerudo Training Grounds Maze Right Side Chest",
    "Gerudo Training Grounds Hammer Room Clear Chest",
    "Gerudo Training Grounds Before Heavy Block Chest",
    "Gerudo Training Grounds Heavy Block First Chest",
    "Gerudo Training Grounds Heavy Block Second Chest",
    "Gerudo Training Grounds Heavy Block Fourth Chest",
    "Spirit Temple Child Left Chest",
    "Spirit Temple Child Climb East Chest",
    "Spirit Temple Child Climb North Chest",
    "Spirit Temple First Mirror Right Chest",
    "Spirit Temple First Mirror Left Chest",
    "Spirit Temple NE Main Room Chest",
    "Silver Gauntlets Chest",
    "Mirror Shield Chest",
    "Spirit Temple Hallway Left Invisible Chest",
    "Spirit Temple Hallway Right Invisible Chest",
    "Spirit Temple Topmost Chest",
    "Twinrova Heart",
    "Ganons Castle Forest Trial Chest",
    "Ganons Castle Water Trial Left Chest",
    "Ganons Castle Water Trial Right Chest",
    "Ganons Castle Shadow Trial First Chest",
    "Ganons Castle Shadow Trial Second Chest",
    "Ganons Castle Spirit Trial First Chest",
    "Ganons Castle Spirit Trial Second Chest",
    "Ganons Castle Light Trial First Left Chest",
    "Ganons Castle Light Trial Second Left Chest",
    "Ganons Castle Light Trial Third Left Chest",
    "Ganons Castle Light Trial First Right Chest",
    "Ganons Castle Light Trial Second Right Chest",
    "Ganons Castle Light Trial Third Right Chest"
];
