import { ItemPoolType } from "../Types/ItemPoolType";
import { DungeonRewardItemPool } from "../DungeonRewards/DungeonRewardItemPool";
import { GerudoCardItemPool } from "./GerudoCardItemPool";
import { GoldSkulltulaItemPool } from "./GoldSkulltulaItemPool";
import { MagicBeanItemPool } from "./MagicBeanItemPool";
import { OcarinaItemPool } from "./OcarinaItemPool";
import { RutoLetterItemPool } from "./RutoLetterItemPool";
import { SongItemPool } from "./SongItemPool";
import { SwordItemPool } from "./SwordItemPool";
import { WeirdEggItemPool } from "./WeirdEggItemPool";

export let StartingItemPool: ItemPoolType = [
    ...DungeonRewardItemPool,
    ...GerudoCardItemPool,
    ...GoldSkulltulaItemPool,
    ...MagicBeanItemPool,
    ...OcarinaItemPool,
    ...RutoLetterItemPool,
    ...SongItemPool,
    ...SwordItemPool,
    ...WeirdEggItemPool,
    "Arrows",
    "Biggoron Sword",
    "Bomb Bag",
    "Bombchus",
    "Boomerang",
    "Bombs",
    "Bottle",
    "Bottle with Red Potion",
    "Bottle with Blue Potion",
    "Bottle with Fairy",
    "Bottle with Fish",
    "Bottle with Blue Fire",
    "Bottle with Bugs",
    "Bottle with Big Poe",
    "Bottle with Green Potion",
    "Bottle with Milk",
    "Bottle with Poe",
    "Bow",
    "Broken Sword",
    "Claim Check",
    "Cojiro",
    "Deku Nut Capacity",
    "Deku Nuts",
    "Deku Seeds",
    "Deku Shield",
    "Deku Sticks",
    "Deku Stick Capacity",
    "Dins Fire",
    "Double Defense",
    "Eyeball Frog",
    "Eyedrops",
    "Farores Wind",
    "Fire Arrows",
    "Goron Tunic",
    "Hammer",
    "Heart Container",
    "Hover Boots",
    "Hylian Shield",
    "Ice Arrows",
    "Iron Boots",
    "Lens of Truth",
    "Light Arrows",
    "Magic Meter",
    "Mirror Shield",
    "Nayrus Love",
    "Odd Mushroom",
    "Odd Potion",
    "Piece of Heart",
    "Poachers Saw",
    "Pocket Cucco",
    "Pocket Egg",
    "Prescription",
    "Progressive Hookshot",
    "Progressive Scale",
    "Progressive Strength Upgrade",
    "Progressive Wallet",
    "Rupees",
    "Slingshot",
    "Stone of Agony",
    "Zora Tunic"
].sort();
