import { LocationPoolType } from "../Types/LocationPoolType";

export let DekuScrubLocationPool: LocationPoolType = [
    "LW Deku Scrub Deku Nuts",
    "LW Deku Scrub Deku Sticks",
    "LW Grotto Deku Scrub Arrows",
    "SFM Grotto Deku Scrub Red Potion",
    "SFM Grotto Deku Scrub Green Potion",
    "LLR Grotto Deku Scrub Deku Nuts",
    "LLR Grotto Deku Scrub Bombs",
    "LLR Grotto Deku Scrub Arrows",
    "Goron Grotto Deku Scrub Deku Nuts",
    "Goron Grotto Deku Scrub Bombs",
    "Goron Grotto Deku Scrub Arrows",
    "DMC Deku Scrub Bombs",
    "DMC Grotto Deku Scrub Deku Nuts",
    "DMC Grotto Deku Scrub Bombs",
    "DMC Grotto Deku Scrub Arrows",
    "ZR Grotto Deku Scrub Red Potion",
    "ZR Grotto Deku Scrub Green Potion",
    "LH Grotto Deku Scrub Deku Nuts",
    "LH Grotto Deku Scrub Bombs",
    "LH Grotto Deku Scrub Arrows",
    "Desert Grotto Deku Scrub Red Potion",
    "Desert Grotto Deku Scrub Green Potion",
    "Valley Grotto Deku Scrub Red Potion",
    "Valley Grotto Deku Scrub Green Potion",
    "DC Deku Scrub Deku Sticks",
    "DC Deku Scrub Deku Shield",
    "DC Deku Scrub Deku Seeds",
    "DC Deku Scrub Deku Nuts",
    "Jabu Deku Scrub Deku Nuts",
    "GC Deku Scrub Bombs",
    "GC Deku Scrub Arrows",
    "GC Deku Scrub Red Potion",
    "GC Deku Scrub Green Potion"
];
