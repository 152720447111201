
export let DungeonInteriors: string[] = [
    "Bottom of the Well",
    "Deku Tree Lobby",
    "Dodongos Cavern Beginning",
    "Fire Temple Lower",
    "Forest Temple Lobby",
    "Gerudo Training Grounds Lobby",
    "Ice Cavern Beginning",
    "Jabu Jabus Belly Beginning",
    "Shadow Temple Entryway",
    "Spirit Temple Lobby",
    "Water Temple Lobby",
];
