import { ItemPoolType } from "../Types/ItemPoolType";

export let VanillaItemPool: ItemPoolType = [
    "Arrows (10)",
    "Arrows (30)",
    "Arrows (5)",
    "Biggoron Sword",
    "Bomb Bag",
    "Bombchus",
    "Bombchus (10)",
    "Bombchus (20)",
    "Bombchus (5)",
    "Bombs (10)",
    "Bombs (20)",
    "Bombs (5)",
    "Boomerang",
    "Bottle",
    "Bottle with Red Potion",
    "Bottle with Blue Potion",
    "Bottle with Fairy",
    "Bottle with Fish",
    "Bottle with Blue Fire",
    "Bottle with Bugs",
    "Bottle with Big Poe",
    "Bottle with Green Potion",
    "Bottle with Milk",
    "Bottle with Poe",
    "Bow",
    "Broken Sword",
    "Buy Arrows (10)",
    "Buy Arrows (30)",
    "Buy Arrows (50)",
    "Buy Blue Fire",
    "Buy Blue Potion",
    "Buy Bombchu (10)",
    "Buy Bombchu (20)",
    "Buy Bombchu (5)",
    "Buy Bombs (10)",
    "Buy Bombs (20)",
    "Buy Bombs (5) [25]",
    "Buy Bombs (5) [35]",
    "Buy Bottle Bug",
    "Buy Deku Nut (10)",
    "Buy Deku Nut (5)",
    "Buy Deku Seeds (30)",
    "Buy Deku Shield",
    "Buy Deku Stick (1)",
    "Buy Fairy's Spirit",
    "Buy Fish",
    "Buy Goron Tunic",
    "Buy Green Potion",
    "Buy Heart",
    "Buy Hylian Shield",
    "Buy Poe",
    "Buy Red Potion [30]",
    "Buy Red Potion [40]",
    "Buy Red Potion [50]",
    "Buy Zora Tunic",
    "Claim Check",
    "Cojiro",
    "Deku Nut Capacity",
    "Deku Nuts (10)",
    "Deku Nuts (5)",
    "Deku Seeds (30)",
    "Deku Shield",
    "Deku Stick (1)",
    "Deku Stick Capacity",
    "Dins Fire",
    "Double Defense",
    "Eyeball Frog",
    "Eyedrops",
    "Farores Wind",
    "Fire Arrows",
    "Goron Tunic",
    "Hammer",
    "Heart Container",
    "Hover Boots",
    "Hylian Shield",
    "Ice Arrows",
    "Ice Trap",
    "Iron Boots",
    "Lens of Truth",
    "Light Arrows",
    "Magic Meter",
    "Mirror Shield",
    "Nayrus Love",
    "Odd Mushroom",
    "Odd Potion",
    "Piece of Heart (Treasure Chest Game)",
    "Piece of Heart",
    "Poachers Saw",
    "Pocket Cucco",
    "Pocket Egg",
    "Prescription",
    "Progressive Hookshot",
    "Progressive Scale",
    "Progressive Strength Upgrade",
    "Progressive Wallet",
    "Recovery Heart",
    "Rupee (1)",
    "Rupees (20)",
    "Rupees (200)",
    "Rupees (5)",
    "Rupees (50)",
    "Slingshot",
    "Stone of Agony",
    "Zora Tunic"
];
