import { LocationPoolType } from "../Types/LocationPoolType";

export let DungeonRewardLocationPool: LocationPoolType = [
    "Barinade",
    "Bongo Bongo",
    "King Dodongo",
    "Links Pocket",
    "Morpha",
    "Phantom Ganon",
    "Queen Gohma",
    "Twinrova",
    "Volvagia"
];
