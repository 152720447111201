import { LocationPoolType } from "../Types/LocationPoolType";

export let LocationPool: LocationPoolType = [
    "Mido Chest Top Left",
    "Mido Chest Top Right",
    "Mido Chest Bottom Left",
    "Mido Chest Bottom Right",
    "Skull Kid",
    "Ocarina Memory Game",
    "Target in Woods",
    "LW Deku Scrub Deku Stick Upgrade", // vanilla deku scrub
    "Lake Hylia Sun",
    "Lake Hylia Freestanding PoH",
    "Diving in the Lab",
    "Child Fishing",
    "Adult Fishing",
    "Gerudo Valley Waterfall Freestanding PoH",
    "Gerudo Valley Crate Freestanding PoH",
    "Gerudo Valley Hammer Rocks Chest",
    "Gerudo Fortress Rooftop Chest",
    "Horseback Archery 1000 Points",
    "Horseback Archery 1500 Points",
    "Gerudo Fortress North F1 Carpenter",
    "Gerudo Fortress North F2 Carpenter",
    "Gerudo Fortress South F1 Carpenter",
    "Gerudo Fortress South F2 Carpenter",
    "Haunted Wasteland Structure Chest",
    "Colossus Freestanding PoH",
    "Desert Colossus Fairy Reward",
    "Hyrule Castle Fairy Reward",
    "Ganons Castle Fairy Reward",
    "10 Big Poes",
    "Child Shooting Gallery",
    "Bombchu Bowling Bomb Bag",
    "Bombchu Bowling Piece of Heart",
    "Treasure Chest Game",
    "Dog Lady",
    "Anju as Adult",
    "Anjus Chickens",
    "Man on Roof",
    "10 Gold Skulltula Reward",
    "20 Gold Skulltula Reward",
    "30 Gold Skulltula Reward",
    "40 Gold Skulltula Reward",
    "50 Gold Skulltula Reward",
    "Impa House Freestanding PoH",
    "Windmill Freestanding PoH",
    "Adult Shooting Gallery",
    "Graveyard Freestanding PoH",
    "Gravedigging Tour",
    "Shield Grave Chest",
    "Heart Piece Grave Chest",
    "Composer Grave Chest",
    "Hookshot Chest",
    "Dampe Race Freestanding PoH",
    "Death Mountain Bombable Chest",
    "DM Trail Freestanding PoH",
    "Biggoron",
    "Goron City Leftmost Maze Chest",
    "Goron City Left Maze Chest",
    "Goron City Right Maze Chest",
    "Goron City Pot Freestanding PoH",
    "Rolling Goron as Child",
    "Link the Goron",
    "Darunias Joy",
    "DM Crater Wall Freestanding PoH",
    "DM Crater Volcano Freestanding PoH",
    "Crater Fairy Reward",
    "Mountain Summit Fairy Reward",
    "Frog Ocarina Game",
    "Frogs in the Rain",
    "Zora River Lower Freestanding PoH",
    "Zora River Upper Freestanding PoH",
    "Diving Minigame",
    "Zoras Domain Torch Run",
    "King Zora Thawed",
    "Zoras Fountain Iceberg Freestanding PoH",
    "Zoras Fountain Bottom Freestanding PoH",
    "Zoras Fountain Fairy Reward",
    "Talons Chickens",
    "Lon Lon Tower Freestanding PoH",
    "Kokiri Forest Storms Grotto Chest",
    "Lost Woods Generic Grotto Chest",
    "Deku Theater Skull Mask",
    "Deku Theater Mask of Truth",
    "LW Grotto Deku Scrub Deku Nut Upgrade", // vanilla deku scrub
    "Wolfos Grotto Chest",
    "Remote Southern Grotto Chest",
    "Field Near Lake Outside Fence Grotto Chest",
    "HF Grotto Deku Scrub Piece of Heart", // vanilla deku scrub
    "Field West Castle Town Grotto Chest",
    "Tektite Grotto Freestanding PoH",
    "Redead Grotto Chest",
    "Kakariko Back Grotto Chest",
    "Mountain Storms Grotto Chest",
    "Top of Crater Grotto Chest",
    "Zora River Plateau Open Grotto Chest"
];
