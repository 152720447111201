
export let SimpleInteriors: string[] = [
    "Carpenter Boss House",
    "Carpenter Tent",
    "Castle Storms Grotto",
    "Castle Town Bazaar",
    "Castle Town Bombchu Bowling",
    "Castle Town Bombchu Shop",
    "Castle Town Man in Green House",
    "Castle Town Mask Shop",
    "Castle Town Potion Shop",
    "Castle Town Rupee Room",
    "Castle Town Shooting Gallery",
    "Castle Town Treasure Chest Game",
    "Colossus Fairy",
    "Composer Grave",
    "Crater Fairy",
    "DMC Hammer Grotto",
    "Dampes House",
    "Deku Theater",
    "Desert Colossus Grotto",
    "Field Far West Castle Town Grotto",
    "Field Kakariko Grotto",
    "Field Near Lake Inside Fence Grotto",
    "Field Near Lake Outside Fence Grotto",
    "Field North Lon Lon Grotto",
    "Field Valley Grotto",
    "Field West Castle Town Grotto",
    "Fishing Hole",
    "Front of Meadow Grotto",
    "Ganons Castle Fairy",
    "Gerudo Fortress Storms Grotto",
    "Gerudo Valley Octorok Grotto",
    "Gerudo Valley Storms Grotto",
    "Goron City Grotto",
    "Goron Shop",
    "Heart Piece Grave",
    "House of Skulltula",
    "House of Twins",
    "Hyrule Castle Fairy",
    "Impas House Back",
    "Impas House",
    "Ingo Barn",
    "Kakariko Back Grotto",
    "Kakariko Bazaar",
    "Kakariko Bombable Grotto",
    "Kakariko Shooting Gallery",
    "Know It All House",
    "Kokiri Forest Storms Grotto",
    "Kokiri Shop",
    "Lake Hylia Grotto",
    "Lake Hylia Lab",
    "Lon Lon Corner Tower",
    "Lon Lon Grotto",
    "Lost Woods Generic Grotto",
    "Lost Woods Sales Grotto",
    "Meadow Fairy Grotto",
    "Meadow Storms Grotto",
    "Mido House",
    "Mountain Bombable Grotto",
    "Mountain Storms Grotto",
    "Mountain Summit Fairy",
    "Odd Medicine Building",
    "Remote Southern Grotto",
    "Saria House",
    "Shield Grave",
    "Talon House",
    "Top of Crater Grotto",
    "Zora River Plateau Bombable Grotto",
    "Zora River Plateau Open Grotto",
    "Zora River Storms Grotto",
    "Zora Shop",
    "Zoras Domain Storms Grotto",
    "Zoras Fountain Fairy"
];
